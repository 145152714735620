import { useState, useCallback, useContext } from 'react'
import { filter, omit, map, sumBy } from 'lodash'
import * as Sentry from '@sentry/react'
import axios from '../axios'
import {
  CheckoutStateContext,
  CheckoutDispatchContext
} from '../context/CheckoutContextProvider'
import { getGclid } from '../context/GlobalContextProvider'
import { trackGAPurchase } from '../context/analytics'

export const useCheckout = (locale) => {
  const state = useContext(CheckoutStateContext)
  const dispatch = useContext(CheckoutDispatchContext)
  const [loading, setLoading] = useState(false)

  const setClientSecret = useCallback((value) => {
    dispatch({
      type: 'SET_CLIENT_SECRET',
      payload: { value }
    })
  }, [])

  const setPaymentType = useCallback((value) => {
    dispatch({
      type: 'SET_PAYMENT_TYPE',
      payload: { value }
    })
  }, [])

  const setIntentId = useCallback((value) => {
    dispatch({
      type: 'SET_INTENT_ID',
      payload: { value }
    })
  }, [])

  const setOrderId = useCallback((value) => {
    dispatch({
      type: 'SET_ORDER_ID',
      payload: { value }
    })
  }, [])

  const setCurrentStep = useCallback((value) => {
    setLoading(false)
    dispatch({
      type: 'SET_STEP',
      payload: { value }
    })
  }, [])

  const updateStepValidation = useCallback((type, value) => {
    dispatch({
      type: 'UPDATE_STEP',
      payload: { type, value }
    })
  }, [])

  const setCustomerInfo = useCallback((type, value) => {
    dispatch({
      type: 'SET_CUSTOMER_INFO',
      payload: { type, value }
    })
  }, [])

  const setCustomerAddress = useCallback((type, value) => {
    dispatch({
      type: 'SET_CUSTOMER_ADDRESS',
      payload: { type, value }
    })
  }, [])

  const setCompanyInfo = useCallback((type, value) => {
    dispatch({
      type: 'SET_COMPANY_INFO',
      payload: { type, value }
    })
  }, [])

  const addTrademark = useCallback((type, key, value) => {
    dispatch({
      type: 'ADD_TRADEMARK',
      payload: { type, key, value }
    })
  }, [])

  const updateTrademark = useCallback((type, key, value) => {
    dispatch({
      type: 'UPDATE_TRADEMARK',
      payload: { type, key, value }
    })
  }, [])

  const removeTrademark = useCallback((type, key) => {
    dispatch({
      type: 'REMOVE_TRADEMARK',
      payload: { type, key }
    })
  }, [])

  const setTrademarkLogos = useCallback((type, value) => {
    dispatch({
      type: 'SET_TRADEMARK_LOGOS',
      payload: { type, value }
    })
  }, [])

  const setSourceWebsite = useCallback((value) => {
    dispatch({
      type: 'SET_SOURCE_WEBSITE',
      payload: { value }
    })
  }, [])

  const resetCheckoutState = useCallback(() => {
    dispatch({ type: 'RESET_CHECKOUT' })
  }, [])

  // handle card payment
  const paymentProcess = useCallback(
    (payload) => {
      setLoading(true)
      var payment_data = {
        customer: omit(state.customer_info, ['phoneNumber']),
        address: omit(state.customer_address, ['address', 'city', 'zip']),
        jurisdiction: payload.configuratorState.country_selection,
        subtotal: payload.configuratorState.subtotal,
        total_price: payload.configuratorState.total_price,
        tax: payload.configuratorState.taxes,
        discount_ttl: payload.configuratorState.discount_total,
        discount_code: payload.configuratorState.discount_code,
        service_level: payload.configuratorState.package_type,
        currency: locale == 'en-CA' ? 'CAD' : 'USD',
        cart: payload.configuratorState.cart,
        products: payload.configuratorState.cart.map((e) => {
          return `${e.type}(${e.qty})`
        })
      }
      axios
        .post(`/checkout`, { ...payment_data })
        .then(async (e) => {
          var full_discount = e.data.full_discount
          var client_secret = e.data.client_secret
          var order_id = e.data.order_id
          var intent_id = e.data.intent_id
          if (full_discount) {
            // full discount functionality
            setOrderId(order_id)
            setPaymentType('stripe')
            // handle logo files upload if part of cart
            let logofiles = (await logoFilesUpload(state)).files
            // Call to create a customer entry in stripe, store data in all apis
            axios
              .post(`/checkout/addCustomer`, {
                full_discount,
                order_id,
                intent_id,
                ...payment_data,
                address_obj: {
                  city: state.customer_address.city,
                  country: state.customer_address.country,
                  line1: state.customer_address.address,
                  postal_code: state.customer_address.zip,
                  state: state.customer_address.state
                },
                email: state.customer_info.email,
                phone: state.customer_info.phoneNumber,
                name:
                  state.customer_info.firstName +
                  ' ' +
                  state.customer_info.lastName,
                applicant_name: state.customer_info.fullName,
                applicant_type: state.customer_info.applicantType,
                billing_address: payload.configuratorState.billing_address,
                company: state.customer_info.fullName,
                address: state.customer_address.address,
                city: state.customer_address.city,
                zip: state.customer_address.zip,
                state: state.customer_address.state,
                country: state.customer_address.country,
                jurisdiction:
                  typeof payload.configuratorState.country_selection == 'object'
                    ? JSON.stringify({
                        ...payload.configuratorState.country_selection
                      })
                    : payload.configuratorState.country_selection,
                service_level: payload.configuratorState.package_type,
                website: state.company_info.website,
                services: state.company_info.services,
                trademarks: JSON.stringify(payload.configuratorState.cart),
                trademarksFormatted: payload.configuratorState.cart
                  .map((e) => {
                    return `${e.qty} - ${e.selection} ${e.type} Trademark${
                      e.qty > 1 ? 's' : ''
                    }\n`
                  })
                  .join(', '),
                trademarkObj: trademarkJSON({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                cartSelectionString: stringifyCart({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                gclid: getGclid(),
                source_website: state.source_website
              })
              .catch((error) => {
                setLoading(false)
                Sentry.captureException(
                  error?.response?.data ||
                    'Error processing Stripe add customer!'
                )
              })
              .finally(() => {
                setLoading(false)
                setCurrentStep((state.current_step = 4))
                // track the order
                trackGAPurchase(
                  order_id,
                  payload.configuratorState,
                  locale == 'en-CA' ? 'CAD' : 'USD'
                )
              })
          } else {
            const result = await payload.stripeData.stripe.confirmCardPayment(
              client_secret,
              {
                payment_method: {
                  type: 'card',
                  card: payload.stripeData.elements?.getElement(
                    payload.stripeData.CardNumberElement
                  ),
                  billing_details: {
                    email: state.customer_info.email,
                    phone: state.customer_info.phoneNumber,
                    name:
                      state.customer_info.firstName +
                      ' ' +
                      state.customer_info.lastName,
                    address: {
                      city: state.customer_address.city,
                      country: state.customer_address.country,
                      line1: state.customer_address.address,
                      postal_code: state.customer_address.zip,
                      state: state.customer_address.state
                    }
                  }
                }
              }
            )
            // handle error
            if (result.error) {
              Sentry.captureException(result.error.message)
              throw new Error(result.error.message)
            }
            // handle success payment
            if (result.paymentIntent.status === 'succeeded') {
              setOrderId(order_id)
              setClientSecret(client_secret)
              setIntentId(intent_id)
              setPaymentType('stripe')
              // handle logo files upload if part of cart
              let logofiles = (await logoFilesUpload(state)).files
              // Call to create a customer entry in stripe, store data in all apis
              axios
                .post(`/checkout/addCustomer`, {
                  order_id,
                  intent_id,
                  client_secret,
                  payment_method: result.paymentIntent.payment_method,
                  address_obj: {
                    city: state.customer_address.city,
                    country: state.customer_address.country,
                    line1: state.customer_address.address,
                    postal_code: state.customer_address.zip,
                    state: state.customer_address.state
                  },
                  email: state.customer_info.email,
                  phone: state.customer_info.phoneNumber,
                  cardName: state.customer_info.cardName,
                  name:
                    state.customer_info.firstName +
                    ' ' +
                    state.customer_info.lastName,
                  applicant_name: state.customer_info.fullName,
                  applicant_type: state.customer_info.applicantType,
                  billing_address: payload.configuratorState.billing_address,
                  company: state.customer_info.fullName,
                  address: state.customer_address.address,
                  city: state.customer_address.city,
                  zip: state.customer_address.zip,
                  state: state.customer_address.state,
                  country: state.customer_address.country,
                  jurisdiction:
                    typeof payload.configuratorState.country_selection ==
                    'object'
                      ? JSON.stringify({
                          ...payload.configuratorState.country_selection
                        })
                      : payload.configuratorState.country_selection,
                  service_level: payload.configuratorState.package_type,
                  website: state.company_info.website,
                  services: state.company_info.services,
                  trademarks: JSON.stringify(payload.configuratorState.cart),
                  trademarksFormatted: payload.configuratorState.cart
                    .map((e) => {
                      return `${e.qty} - ${e.selection} ${e.type} Trademark${
                        e.qty > 1 ? 's' : ''
                      }\n`
                    })
                    .join(', '),
                  trademarkObj: trademarkJSON({
                    state: state,
                    configuratorState: payload.configuratorState,
                    logoFileNames: logofiles && logofiles
                  }),
                  cartSelectionString: stringifyCart({
                    state: state,
                    configuratorState: payload.configuratorState,
                    logoFileNames: logofiles && logofiles
                  }),
                  gclid: getGclid(),
                  source_website: state.source_website
                })
                .catch((error) => {
                  setLoading(false)
                  Sentry.captureException(
                    error?.response?.data ||
                      'Error processing Stripe add customer!'
                  )
                })
                .finally(() => {
                  setLoading(false)
                  setCurrentStep((state.current_step = 4))
                  // track the order
                  trackGAPurchase(
                    order_id,
                    payload.configuratorState,
                    locale == 'en-CA' ? 'CAD' : 'USD'
                  )
                })
            }
          }
        })
        .catch((error) => {
          setLoading(false)
          Sentry.captureException(
            error?.response?.data || 'Error processing Stripe payment!'
          )
          payload.fail_callback()
        })
    },
    [setCurrentStep, setOrderId, state]
  )
  // handle stipe elements payment using GPay & Apple Pay
  const elementsPaymentProcess = useCallback(
    (payload) => {
      setLoading(true)
      var payment_data = {
        customer: omit(state.customer_info, ['phoneNumber']),
        address: omit(state.customer_address, ['address', 'city', 'zip']),
        subtotal: payload.configuratorState.subtotal,
        total_price: payload.configuratorState.total_price,
        tax: payload.configuratorState.taxes,
        discount_ttl: payload.configuratorState.discount_total,
        discount_code: payload.configuratorState.discount_code,
        jurisdiction: payload.configuratorState.country_selection,
        service_level: payload.configuratorState.package_type,
        cart: payload.configuratorState.cart,
        currency: locale == 'en-CA' ? 'CAD' : 'USD',
        products: payload.configuratorState.cart.map((e) => {
          return `${e.type}(${e.qty})`
        })
      }
      axios
        .post(`/checkout`, {
          ...payment_data
        })
        .then(async (e) => {
          var client_secret = e.data.client_secret
          var order_id = e.data.order_id
          var intent_id = e.data.intent_id

          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await payload.stripe.confirmCardPayment(
              client_secret,
              { payment_method: payload.ev.paymentMethod.id },
              { handleActions: false }
            )

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            payload.ev.complete('fail')
            Sentry.captureException(confirmError)
            throw new Error(confirmError)
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            payload.ev.complete('success')
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11" instead
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await payload.stripe.confirmCardPayment(
                client_secret
              )
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                Sentry.captureException(confirmError)
                throw new Error(confirmError)
              } else {
                // The payment has succeeded.
              }
            } else {
              // The payment has succeeded.
            }
            setOrderId(order_id)
            setClientSecret(client_secret)
            setIntentId(intent_id)
            setPaymentType('stripe')
            // handle logo files upload if part of cart
            let logofiles = (await logoFilesUpload(state)).files
            // Call to create a customer entry in stripe, store data in all apis
            axios
              .post(`/checkout/addCustomer`, {
                order_id,
                intent_id,
                client_secret,
                payment_method: paymentIntent.payment_method,
                address_obj: {
                  city: state.customer_address.city,
                  country: state.customer_address.country,
                  line1: state.customer_address.address,
                  postal_code: state.customer_address.zip,
                  state: state.customer_address.state
                },
                email: state.customer_info.email,
                phone: state.customer_info.phoneNumber,
                name:
                  state.customer_info.firstName +
                  ' ' +
                  state.customer_info.lastName,
                company: state.customer_info.fullName,
                applicant_name: state.customer_info.fullName,
                applicant_type: state.customer_info.applicantType,
                address: state.customer_address.address,
                city: state.customer_address.city,
                zip: state.customer_address.zip,
                state: state.customer_address.state,
                country: state.customer_address.country,
                billing_address: payload.configuratorState.billing_address,
                jurisdiction:
                  typeof payload.configuratorState.country_selection == 'object'
                    ? JSON.stringify({
                        ...payload.configuratorState.country_selection
                      })
                    : payload.configuratorState.country_selection,
                service_level: payload.configuratorState.package_type,
                website: state.company_info.website,
                services: state.company_info.services,
                trademarks: JSON.stringify(payload.configuratorState.cart),
                trademarksFormatted: payload.configuratorState.cart
                  .map((e) => {
                    return `${e.qty} - ${e.selection} ${e.type} Trademark${
                      e.qty > 1 ? 's' : ''
                    }\n`
                  })
                  .join(', '),
                trademarkObj: trademarkJSON({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                cartSelectionString: stringifyCart({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                gclid: getGclid(),
                source_website: state.source_website
              })
              .catch((error) => {
                setLoading(false)
                Sentry.captureException(
                  error?.response?.data ||
                    'Error processing Stripe elements add customer!'
                )
              })
              .finally(() => {
                setLoading(false)
                setCurrentStep((state.current_step = 4))
                // track the order
                trackGAPurchase(
                  order_id,
                  payload.configuratorState,
                  locale == 'en-CA' ? 'CAD' : 'USD'
                )
              })
          }
        })
        .catch((error) => {
          setLoading(false)
          Sentry.captureException(
            error?.response?.data || 'Error processing Stripe elements payment!'
          )
          payload.fail_callback()
        })
    },
    [setCurrentStep, state]
  )
  // handle paypal create order call
  const createPayPalOrder = async (payload) => {
    var payment_data = {
      subtotal: payload.configuratorState.subtotal,
      total_price: payload.configuratorState.total_price.toFixed(2),
      tax: payload.configuratorState.taxes.toFixed(2),
      products: payload.configuratorState.cart,
      country: payload.configuratorState.country_selection,
      discount: payload.configuratorState.discount_total.toFixed(2),
      service_level: payload.configuratorState.package_type,
      currency: locale == 'en-CA' ? 'CAD' : 'USD'
    }
    const response = await axios
      .post(`/checkout/paypal/create`, {
        ...payment_data
      })
      .catch((error) => {
        Sentry.captureException(
          error?.response?.data || 'Error processing Paypal payment!'
        )
        payload.fail_callback()
      })
    return response.data.result.id
  }
  // handle paypal approve order call
  const approvedPayPalOrder = useCallback(
    async (payload) => {
      setLoading(true)
      const response = await axios
        .post(`/checkout/paypal/approve`, {
          orderID: payload.orderID,
          subtotal: payload.configuratorState.subtotal.toString(),
          tax: payload.configuratorState.taxes.toFixed(2),
          discount: payload.configuratorState.discount_total.toFixed(2),
          discount_code: payload.configuratorState.discount_code,
          state: payload.configuratorState.billing_address.state,
          currency: locale == 'en-CA' ? 'CAD' : 'USD',
          total: payload.configuratorState.total_price.toString(),
          billing_name:
            state.customer_info.firstName + ' ' + state.customer_info.lastName,
          billing_address: JSON.stringify({
            city: state.customer_address.city,
            country: state.customer_address.country,
            line1: state.customer_address.address,
            postal_code: state.customer_address.zip,
            state: state.customer_address.state
          }),
          jurisdiction:
            typeof payload.configuratorState.country_selection == 'object'
              ? JSON.stringify({
                  ...payload.configuratorState.country_selection
                })
              : payload.configuratorState.country_selection,
          service_level: payload.configuratorState.package_type,
          trademarks: JSON.stringify(payload.configuratorState.cart),
          website: state.company_info.website,
          services: state.company_info.services
        })
        .then(async (res) => {
          var payment_id = res.data.result.id
          if (res.data.result.status == 'COMPLETED') {
            setOrderId(
              res.data.result.purchase_units[0].payments.captures[0].invoice_id
            )
            setIntentId(payment_id)
            setPaymentType('paypal')
            // handle logo files upload if part of cart
            let logofiles = (await logoFilesUpload(state)).files
            // Call to create a customer entry in stripe, store data in all apis
            axios
              .post(`/checkout/paypal/addCustomer`, {
                payment_id,
                order_id: state.order_id,
                address_obj: {
                  city: state.customer_address.city,
                  country: state.customer_address.country,
                  line1: state.customer_address.address,
                  postal_code: state.customer_address.zip,
                  state: state.customer_address.state
                },
                company_name: state.customer_info.fullName,
                applicant_name: state.customer_info.fullName,
                applicant_type: state.customer_info.applicantType,
                email: state.customer_info.email,
                phone: state.customer_info.phoneNumber,
                name:
                  state.customer_info.firstName +
                  ' ' +
                  state.customer_info.lastName,
                firstname: state.customer_info.firstName,
                lastname: state.customer_info.lastName,
                address: state.customer_address.address,
                city: state.customer_address.city,
                zip: state.customer_address.zip,
                state: state.customer_address.state,
                country: state.customer_address.country,
                billing_state: payload.configuratorState.billing_address.state,
                jurisdiction: payload.configuratorState.country_selection,
                discount: payload.configuratorState.discount_total.toFixed(2),
                discount_code: payload.configuratorState.discount_code,
                service_level: payload.configuratorState.package_type,
                website: state.company_info.website,
                services: state.company_info.services,
                trademarks: payload.configuratorState.cart
                  .map((e) => {
                    return `${e.qty} - ${e.selection} ${e.type} Trademark${
                      e.qty > 1 ? 's' : ''
                    }\n`
                  })
                  .join(', '),
                trademarkObj: trademarkJSON({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                cartSelectionString: stringifyCart({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                gclid: getGclid(),
                source_website: state.source_website
              })
              .catch((error) => {
                setLoading(false)
                Sentry.captureException(
                  error?.response?.data ||
                    'Error processing Paypal add customer!'
                )
              })
              .finally(() => {
                setLoading(false)
                setCurrentStep((state.current_step = 4))
                // track the order
                trackGAPurchase(
                  res.data.result.purchase_units[0].payments.captures[0]
                    .invoice_id,
                  payload.configuratorState,
                  locale == 'en-CA' ? 'CAD' : 'USD'
                )
              })
          }
        })
        .catch((error) => {
          setLoading(false)
          Sentry.captureException(
            error?.response?.data || 'Error processing Paypal payment!'
          )
          payload.fail_callback()
        })
      return response
    },
    [setCurrentStep, state]
  )

  // handle sezzle auth call
  const createSezzleAuth = (payload) => {
    var payment_data = {
      customer: omit(state.customer_info),
      address: omit(state.customer_address),
      subtotal: payload.configuratorState.subtotal,
      total_price: payload.configuratorState.total_price,
      tax: payload.configuratorState.taxes.toFixed(2),
      currency: locale == 'en-CA' ? 'CAD' : 'USD',
      discount_code: payload.configuratorState.discount_code,
      discount_total: payload.configuratorState.discount_total.toFixed(2),
      jurisdiction: payload.configuratorState.country_selection,
      service_level: payload.configuratorState.package_type,
      cart: payload.configuratorState.cart,
      products: JSON.stringify(
        payload.configuratorState.cart.map((e) => {
          return `${e.type}(${e.qty})`
        })
      ),
      country: state.customer_address.country
    }
    axios
      .post(`/sezzle/auth`, {
        ...payment_data
      })
      .then((res) => {
        payload.checkout.startCheckout({
          checkout_payload: {
            order: res.data.order
          }
        })
      })
      .catch((error) => {
        Sentry.captureException(
          error?.response?.data || 'Error processing Sezzle payment!'
        )
        payload.fail_callback()
      })
  }

  // handle sezzle create call
  const captureSezzleOrder = (payload) => {
    setLoading(true)
    var payment_data = {
      total_price: payload.configuratorState.total_price,
      country: state.customer_address.country,
      state: payload.configuratorState.billing_address.state,
      currency: locale == 'en-CA' ? 'CAD' : 'USD',
      subtotal: payload.configuratorState.subtotal.toString(),
      tax: payload.configuratorState.taxes.toFixed(2),
      discount: payload.configuratorState.discount_total.toFixed(2),
      discount_code: payload.configuratorState.discount_code,
      total: payload.configuratorState.total_price.toString(),
      billing_name:
        state.customer_info.firstName + ' ' + state.customer_info.lastName,
      billing_address: JSON.stringify({
        city: state.customer_address.city,
        country: state.customer_address.country,
        line1: state.customer_address.address,
        postal_code: state.customer_address.zip,
        state: state.customer_address.state
      }),
      jurisdiction:
        typeof payload.configuratorState.country_selection == 'object'
          ? JSON.stringify({
              ...payload.configuratorState.country_selection
            })
          : payload.configuratorState.country_selection,
      service_level: payload.configuratorState.package_type,
      trademarks: JSON.stringify(payload.configuratorState.cart),
      website: state.company_info.website,
      services: state.company_info.services
    }
    if (
      payload.event.data.status == 'success' &&
      payload.event.data.order_uuid != null
    ) {
      axios
        .post(`/sezzle/create`, {
          order_uuid: payload.event.data.order_uuid,
          ...payment_data
        })
        .then(async (res) => {
          if (res.data.approved == true) {
            setOrderId(res.data.order_id)
            setIntentId(res.data.order_uuid)
            setPaymentType('sezzle')
            // handle logo files upload if part of cart
            let logofiles = (await logoFilesUpload(state)).files
            // Call to create a customer entry in stripe, store data in all apis
            axios
              .post(`/sezzle/addCustomer`, {
                order_uuid: res.data.order_uuid,
                email: state.customer_info.email,
                firstname: state.customer_info.firstName,
                lastname: state.customer_info.lastName,
                applicant_name: state.customer_info.fullName,
                applicant_type: state.customer_info.applicantType,
                phone: state.customer_info.phoneNumber,
                address: state.customer_address.address,
                city: state.customer_address.city,
                zip: state.customer_address.zip,
                state: state.customer_address.state,
                country: state.customer_address.country,
                billing_state: payload.configuratorState.billing_address.state,
                jurisdiction: payload.configuratorState.country_selection,
                discount: payload.configuratorState.discount_total.toFixed(2),
                discount_code: payload.configuratorState.discount_code,
                service_level: payload.configuratorState.package_type,
                website: state.company_info.website,
                services: state.company_info.services,
                trademarks: payload.configuratorState.cart
                  .map((e) => {
                    return `${e.qty} - ${e.selection} ${e.type} Trademark${
                      e.qty > 1 ? 's' : ''
                    }\n`
                  })
                  .join(', '),
                trademarkObj: trademarkJSON({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                cartSelectionString: stringifyCart({
                  state: state,
                  configuratorState: payload.configuratorState,
                  logoFileNames: logofiles && logofiles
                }),
                gclid: getGclid(),
                source_website: state.source_website
              })
              .catch((error) => {
                setLoading(false)
                Sentry.captureException(
                  error?.response?.data ||
                    'Error processing Sezzle add customer!'
                )
              })
              .finally(() => {
                setCurrentStep((state.current_step = 4))
                setLoading(false)
                // track the order
                trackGAPurchase(
                  res.data.order_id,
                  payload.configuratorState,
                  locale == 'en-CA' ? 'CAD' : 'USD'
                )
                // set to true to prevent sezzle from calling multiple times
                payload.setOrderComplete(true)
              })
          }
        })
        .catch((error) => {
          setLoading(false)
          Sentry.captureException(
            error?.response?.data || 'Error processing Sezzle payment!'
          )
          payload.fail_callback()
        })
    }
  }

  // handle Clearco create order
  const createClearcoOrder = (payload) => {
    var payment_data = {
      cart: payload.configuratorState.cart,
      tax: payload.configuratorState.taxes.toFixed(2),
      discount_total: payload.configuratorState.discount_total.toFixed(2),
      service_level: payload.configuratorState.package_type,
      jurisdiction: payload.configuratorState.country_selection,
      currency: locale == 'en-CA' ? 'CAD' : 'USD'
    }
    axios
      .post(`/clearco/create`, {
        ...payment_data
      })
      .then((response) => {
        setOrderId(response.data.externalId)
        setIntentId(response.data.id)
        setPaymentType('clearco')
        payload.success_callback(response.data.orderToken, response.data.id)
      })
      .catch((error) => {
        Sentry.captureException(
          error?.response?.data || 'Error processing Clearco payment!'
        )
        payload.fail_callback()
      })
  }

  // handle Clearco success order
  const addClearcoCustomer = useCallback(
    async (payload) => {
      setLoading(true)
      var order_data = {
        state: state.customer_address.state,
        subtotal: payload.configuratorState.subtotal.toFixed(2),
        tax: payload.configuratorState.taxes.toFixed(2),
        discount: payload.configuratorState.discount_total.toFixed(2),
        discount_code: payload.configuratorState.discount_code,
        billing_name: `${state.customer_info.firstName} ${state.customer_info.lastName}`,
        billing_address: JSON.stringify({
          line1: state.customer_address.address,
          city: state.customer_address.city,
          state: state.customer_address.state,
          country: state.customer_address.country,
          postal_code: state.customer_address.zip
        }),
        jurisdiction: payload.configuratorState.country_selection,
        service_level: payload.configuratorState.package_type,
        trademarks: JSON.stringify(payload.configuratorState.cart),
        website: state.company_info.website,
        services: state.company_info.services
      }
      var customer_data = {
        firstname: state.customer_info.firstName,
        lastname: state.customer_info.lastName,
        email: state.customer_info.email,
        applicant_name: state.customer_info.fullName,
        applicant_type: state.customer_info.applicantType,
        phone: state.customer_info.phoneNumber,
        address: state.customer_address.address,
        city: state.customer_address.city,
        zip: state.customer_address.zip,
        state: state.customer_address.state,
        country: state.customer_address.country
      }
      // handle logo files upload if part of cart
      let logofiles = (await logoFilesUpload(state)).files
      // Call to create a customer entry in stripe, store data in all apis
      axios
        .post(`/clearco/addCustomer`, {
          order_id: payload.orderId,
          ...order_data,
          ...customer_data,
          billing_state: payload.configuratorState.billing_address.state,
          jurisdictionString:
            typeof payload.configuratorState.country_selection == 'object'
              ? JSON.stringify({
                  ...payload.configuratorState.country_selection
                })
              : payload.configuratorState.country_selection,
          trademarksSelection: payload.configuratorState.cart
            .map((e) => {
              return `${e.qty} - ${e.selection} ${e.type} Trademark${
                e.qty > 1 ? 's' : ''
              }\n`
            })
            .join(', '),
          trademarkObj: trademarkJSON({
            state: state,
            configuratorState: payload.configuratorState,
            logoFileNames: logofiles && logofiles
          }),
          cartSelectionString: stringifyCart({
            state: state,
            configuratorState: payload.configuratorState,
            logoFileNames: logofiles && logofiles
          }),
          gclid: getGclid(),
          source_website: state.source_website
        })
        .catch((error) => {
          setLoading(false)
          Sentry.captureException(
            error?.response?.data || 'Error processing Clearco add customer!'
          )
        })
        .finally(() => {
          setCurrentStep((state.current_step = 4))
          setLoading(false)
          // track the order
          trackGAPurchase(
            state.order_id,
            payload.configuratorState,
            locale == 'en-CA' ? 'CAD' : 'USD'
          )
        })
    },
    [setCurrentStep, state]
  )

  const detailsUpdate = useCallback(
    (payload) => {
      var customer_data = {
        address: {
          city: state.customer_address.city,
          country: state.customer_address.country,
          line1: state.customer_address.address,
          postal_code: state.customer_address.zip,
          state: state.customer_address.state
        },
        email: state.customer_info.email,
        phone: state.customer_info.phoneNumber,
        name: state.customer_info.firstName + ' ' + state.customer_info.lastName
      }
      axios
        .post(`/checkout/update`, {
          intent_id: state.intentId,
          payment_type: state.payment_type,
          ...(!state.client_secret && { ...customer_data }),
          additiona_data: payload.additionalData
        })
        .then((e) => {
          setCurrentStep((state.current_step += 1))
        })
    },
    [setCurrentStep, state]
  )

  // function to upload any logo files, and callback with the file names as a response
  const logoFilesUpload = async (state) => {
    if (state.trademarks.logos.length > 0) {
      var logosForm = createLogosFormData()
      try {
        var res = await axios.post(`/checkout/upload`, logosForm.formData)
        return { files: res.data }
      } catch (error) {
        Sentry.captureException(
          error?.response?.data || 'Error uploading logo file!'
        )
        return { files: null }
      }
    } else {
      return { files: null }
    }
  }

  // creating the form data/config to be used for the upload api endpoint
  function createLogosFormData() {
    const formData = new FormData()
    formData.append('fileCount', state.trademarks.logos.length)
    state.trademarks.logos.map((logo, index) => {
      formData.append(`file_${index}`, logo.file)
    })

    return { formData }
  }

  function trademarkJSON(payload) {
    var obj = {}
    payload.configuratorState.cart.map((trademark, index) => {
      switch (trademark.type) {
        case 'name':
          obj = {
            ...obj,
            names: {
              qty: obj?.names?.qty
                ? obj?.names?.qty + trademark.qty
                : trademark.qty,
              selection:
                obj?.names?.selection != 'initial'
                  ? trademark.selection
                  : obj.names.selection,
              values: {
                ...payload.state.trademarks.names.map((e, index) => {
                  return { [`name${index}`]: Object.values(e)[0] }
                })
              }
            }
          }
          break
        case 'tagline':
          obj = {
            ...obj,
            taglines: {
              qty: obj?.taglines?.qty
                ? obj?.taglines?.qty + trademark.qty
                : trademark.qty,
              selection:
                obj?.taglines?.selection != 'initial'
                  ? trademark.selection
                  : obj.taglines,
              values: {
                ...payload.state.trademarks.taglines.map((e, index) => {
                  return { [`tagline${index}`]: Object.values(e)[0] }
                })
              }
            }
          }
          break
        case 'logo':
          obj = {
            ...obj,
            logos: {
              qty: obj?.logos?.qty
                ? obj?.logos?.qty + trademark.qty
                : trademark.qty,
              selection:
                obj?.logos?.selection != 'initial'
                  ? trademark.selection
                  : obj.logos,
              values: payload.logoFileNames
            }
          }
          break
        default:
          break
      }
    })
    obj.maxTtl = map(obj, (e) => {
      return e.qty
    })
      .sort()
      .reverse()[0]

    return JSON.stringify(obj)
  }

  function stringifyCart(payload) {
    var trademarks = {
      name: {
        selection: {},
        values: []
      },
      tagline: {
        selection: {},
        values: []
      },
      logo: {
        selection: {},
        values: []
      }
    }

    map(trademarks, (tm, key) => {
      trademarks[key].selection = stringifySelections(
        filter(payload.configuratorState.cart, { type: key })
      )
      const logoQty = sumBy(
        filter(payload.configuratorState.cart, { type: 'logo' }),
        'qty'
      )

      if (key == 'logo') {
        try {
          for (let index = 0; index < logoQty; index++) {
            if (
              trademarks[key].values.indexOf(
                '_URL_' + payload.logoFileNames[index]
              ) === -1
            ) {
              trademarks[key].values.push(
                payload.logoFileNames[index]
                  ? '_URL_' + payload.logoFileNames[index]
                  : 'EMPTY'
              )
            } else {
              trademarks[key].values.push('EMPTY')
            }
          }
        } catch (error) {
          for (let index = 0; index < logoQty; index++) {
            trademarks[key].values[index] = 'EMPTY'
          }
        }
      } else {
        trademarks[key].values = payload.state.trademarks[`${key}s`].map(
          (o) => {
            return Object.values(o)[0] ? Object.values(o)[0] : 'EMPTY'
          }
        )
      }
    })

    return JSON.stringify(trademarks)
  }

  function stringifySelections(selections) {
    return map(selections, (item) => {
      return `${item.qty} - ${item.selection} ${item.type} Trademark${
        item.qty > 1 ? 's' : ''
      }\n`
    })
  }

  return {
    state,
    setCurrentStep,
    updateStepValidation,
    setCustomerInfo,
    setCustomerAddress,
    setCompanyInfo,
    paymentProcess,
    elementsPaymentProcess,
    detailsUpdate,
    createPayPalOrder,
    approvedPayPalOrder,
    createSezzleAuth,
    captureSezzleOrder,
    createClearcoOrder,
    addClearcoCustomer,
    addTrademark,
    updateTrademark,
    removeTrademark,
    setTrademarkLogos,
    setLoading,
    trademarkJSON,
    resetCheckoutState,
    loading,
    setSourceWebsite
  }
}
