import { sumBy, findIndex } from 'lodash'
/**
 * Handle checkout event with items and step number. An load of checkout page with no URL params will be an empty cart,
 * so in that case no products.
 * @param items
 * @param stepNumber
 * @param currency
 */
export function trackGACheckout(items, stepNumber, currency) {
  // We run GA in production and staging (different ID for testing GTM/analytics)
  if (process.env.GATSBY_APP_ENV === 'local') {
    // console.log('Not logging GA checkout event in local mode.')
    return
  }

  const products = buildGACommerceProducts(items, currency)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      currency: currency,
      checkout: {
        actionField: {
          step: stepNumber
        },
        products: products
      }
    }
  })
}

export function trackGAAddToCart(items, currency, firstLoad = false) {
  // We run GA in production and staging (different ID for testing GTM/analytics)
  if (process.env.GATSBY_APP_ENV === 'local') {
    // console.log('Not logging GA addToCart event in local mode.')
    return
  }

  const products = buildGACommerceProducts(items, currency, true)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currency: currency,
      add: {
        products: products
      }
    }
  })

  // A bit of a hack. If this is the first call to add to cart, we need to afterward fire checkout step 1 event.
  if (firstLoad) {
    trackGACheckout(items, 1, currency)
  }
}

/**
 * Items need to be in the format (product_name is optional and should only show up for addon package items):
 * {
 *  id: "MJrPIolCb"
 *  is_bundle: true
 *  package_name: "optimal"
 *  price: 99900
 *  quantity: 1
 *  service_level: "light"
 * }
 * @param items
 * @returns {*}
 */
function buildGACommerceProducts(state, currency, singleItem = false) {
  if (singleItem) {
    return state.cart.map((item, index) => {
      const id = `${item.type}_trademark_${
        typeof state.country_selection == 'object'
          ? state.country_selection.join('_').toLowerCase()
          : state.country_selection.toLowerCase()
      }`
      return {
        id: `${id}`,
        name: `${item.type} trademark`,
        price:
          state.productsData[item.selection][state.package_type][currency]
            .price,
        category: `${state.package_type}`,
        quantity: '1',
        variant: `${item.selection}`
      }
    })
  } else {
    var cartItems = getItems(state)
    return cartItems.map((item, index) => {
      return {
        id: `${item.id}_${
          typeof state.country_selection == 'object'
            ? state.country_selection.join('_').toLowerCase()
            : state.country_selection.toLowerCase()
        }`,
        name: `${item.name}`,
        price: item.total_price,
        category: `${state.package_type}`,
        quantity: item.qty,
        variant: `${item.variant}`
      }
    })
  }
}
function getItems(state) {
  var tms = ['name', 'logo', 'tagline']
  var tempObj = []
  tms.map((item, index) => {
    state.cart.map((cartItem, i) => {
      if (cartItem.type == item) {
        if (findIndex(tempObj, { id: `${cartItem.type}_trademark` }) == -1) {
          tempObj.push({
            id: `${cartItem.type}_trademark`,
            name: `${cartItem.type} trademark`,
            qty: `${sumTotal(state.cart, cartItem.type)}`,
            total_price: parseFloat(state.prices[cartItem.type].toFixed(2)),
            variant: `${cartItem.selection}`
          })
        }
      }
    })
  })
  return tempObj
}
function sumTotal(cart, type) {
  try {
    return sumBy(
      cart.filter((item) => {
        return item.type == type
      }),
      'qty'
    )
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function trackGARemoveFromCart(items, currency) {
  // We run GA in production and staging (different ID for testing GTM/analytics)
  if (process.env.GATSBY_APP_ENV === 'local') {
    // console.log('Not logging GA removeFromCart event in local mode.')
    return
  }

  const products = buildGACommerceProducts(items, currency, true)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      currency: currency,
      remove: {
        products: products
      }
    }
  })
}

// subtotal,
// discount,
// tax,
// discountCode,
export function trackGAPurchase(orderId, state, currency) {
  // We run GA in production and staging (different ID for testing GTM/analytics)
  if (process.env.GATSBY_APP_ENV === 'local') {
    // console.log('Not logging GA purchase event in local mode.')
    return
  }

  const total = (state.subtotal + state.taxes - state.discount_total).toFixed(2)
  const products = buildGACommerceProducts(state, currency)

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: `${orderId}`,
          revenue: total,
          tax: state.taxes.toFixed(2),
          coupon: `${state.discount_code}`
        },
        products: products
      }
    }
  })
}
