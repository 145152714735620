import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'gatsby'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from 'date-fns'

import yellowHeart from '../../assets/images/discountBanner/heart_yellow.svg'
import greenHeart from '../../assets/images/discountBanner/heart_green.svg'
import orangeHeart from '../../assets/images/discountBanner/heart_orange.svg'

/**
 * The discount banner will display number of days remaining until it reaches the number of hours below and then will switch to hh:mm:ss counter
 * This value may be changed in the future for sales/marketing purposes
 */
const switchToCountdownInHours = 24

const monthlyDiscount = {
  end: new Date('Feb 28, 2022 12:00:00 GMT-0400')
}

const calculateTimeRemaining = (startDate) => {
  const diffInDays = differenceInDays(monthlyDiscount.end, startDate)
  const diffInHours = differenceInHours(monthlyDiscount.end, startDate)
  const diffInMins = Math.floor(
    (differenceInMinutes(monthlyDiscount.end, startDate) / 60 - diffInHours) *
      60
  )
  const diffInSec = Math.floor(
    differenceInSeconds(monthlyDiscount.end, startDate) -
      differenceInMinutes(monthlyDiscount.end, startDate) * 60
  )
  // display countdown in days
  if (diffInHours > switchToCountdownInHours) {
    return `Ends in  ${diffInDays}  day${diffInDays > 1 ? 's' : ''}`
  } else {
    // displays countdown timer in HH:MM:SS
    return `Ends in ${diffInHours < 10 ? 0 : ''}${diffInHours}:${
      diffInMins < 10 ? 0 : ''
    }${diffInMins}:${diffInSec < 10 ? 0 : ''}${diffInSec}`
  }
}

const DiscountBanner = ({ locale }) => {
  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(new Date())
  )
  useEffect(() => {
    var timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(new Date()))
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [timeRemaining])
  return (
    <Container className='discount-banner px-0 pb-2' fluid>
      <Container className='px-md-0'>
        <Row className='justify-content-center'>
          <Col
            className='icons d-flex justify-content-between align-items-start'
            sm={3}
            xs={2}
          >
            <Image src={yellowHeart} />
            <Image className='d-none d-sm-inline' src={greenHeart} />
            <Image className='d-none d-sm-inline' src={orangeHeart} />
          </Col>
          <Col className='text-center d-flex align-items-center justify-content-center'>
            <p className='pb-md-0 pb-2'>
              Save 20% with the with code <u>LOVE20</u>.{' '}
              {differenceInSeconds(new Date(monthlyDiscount.end), new Date()) <
              0 ? (
                <>But hurry! Ends Tonight. </>
              ) : (
                differenceInDays(monthlyDiscount.end, new Date()) < 8 && (
                  <>But hurry! {timeRemaining}. </>
                )
              )}
              <Link
                className='order-now-link'
                to={`/${locale?.toLowerCase()}/checkout`}
              >
                {' '}
                Start Here
              </Link>
            </p>
          </Col>
          <Col
            className='icons d-flex justify-content-between align-items-start'
            sm={3}
            xs={2}
          >
            <Image className='d-none d-sm-inline' src={orangeHeart} />
            <Image className='d-none d-sm-inline' src={greenHeart} />
            <Image src={yellowHeart} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default DiscountBanner
