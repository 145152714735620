import React, { useEffect, useState, useContext } from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import { GlobalStateContext } from '../../../context/GlobalContextProvider'
import { Link, navigate } from 'gatsby'
import logo from '../../../assets/images/Heirlume_by_Haloo2.svg'
import logoMobile from '../../../assets/images/Heirlume_by_Haloo_mobile2.svg'
import { ChevronUp } from '@styled-icons/boxicons-regular/'
import LanguageSelect from '../LanguageSelect'
import hamburgerIcon from '../../../assets/images/Mobile-Nav-Icon-Lines-Only.svg'
import { useCheckout } from '../../../hooks/useCheckout'

const MOBILE_VIEW = 'mobile'
const DESKTOP_VIEW = 'desktop'

const Header = (props) => {
  const state = useContext(GlobalStateContext)
  var checkoutState = useCheckout(props.locale)
  // track screen width for mobile/desktop navbar respective functionality
  const [viewSize, setViewSize] = useState(0)
  // mobile mainmenu state
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  
  // on re-render set view type and for desktop handle submenu close on scroll
  useEffect(() => {
    let prevScrollPos = window.pageYOffset
    // scroll func to close desktop submenu if opened
    const onScroll = () => {
      const currentScrollPos = window.pageYOffset
      if (currentScrollPos < 50 && viewSize == DESKTOP_VIEW) {
        document.querySelector('.header-container').style.top = 0
      } else if (currentScrollPos > prevScrollPos && viewSize == DESKTOP_VIEW) {
        document.querySelector('.header-container').style.top = '0%'
      } else if (currentScrollPos < prevScrollPos && viewSize == DESKTOP_VIEW) {
        document.querySelector('.header-container').style.top = 0
      }

      prevScrollPos = currentScrollPos
    }
    // resize func to set screen width type
    const handleResize = () => {
      if (window.innerWidth <= 991 && window.innerWidth >= 767) {
        setViewSize(MOBILE_VIEW)
        document.querySelector('.header-container').style.top = 0
      } else {
        setViewSize(DESKTOP_VIEW)
        setOpenMobileMenu(false)
      }
    }
    // call on mounted to get inital type
    handleResize()
    // assign each listener
    document.addEventListener('scroll', onScroll)
    window.addEventListener('resize', handleResize)
    // remove listeners on unmount
    return () => {
      document.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', handleResize)
    }
  })

  // onClick handler to close the entire menu on mobile
  const cloakClickHandler = () => {
    if (openMobileMenu && viewSize == MOBILE_VIEW) {
      setOpenMobileMenu(false)
    }
  }
  // onClick handler to show mobile
  const menuClickHandler = (e) => {
    e.preventDefault()
    setOpenMobileMenu(!openMobileMenu)
  }

  const navigateToCheckout = (e) => {
    let index = e.target.dataset.index
    // if final confirmation step and checkout link clicked go to first step
    if (index == 5 && checkoutState.state.current_step === 5) {
      checkoutState.setCurrentStep(0)
    }
    // else navigate to normal checkout or get in touch page
    navigate(`${props.href}${props.data?.pagesLinks[index]}`)
  }

  // getting the json value of the aria obj from contentful
  const ariaJSON = JSON.parse(props.data?.ariaLabels.internal.content)

  return (
    <Container fluid className='header-container px-0'>
      <div
        className={`overlay-cloak ${
          openMobileMenu && viewSize == MOBILE_VIEW ? 'menu-opened' : ''
        }`}
        onClick={(e) => cloakClickHandler(e)}
      ></div>
      <Container
        className={`bg-white menu-wrapper py-1 py-md-0 ${
          openMobileMenu && viewSize == MOBILE_VIEW ? 'menu-opened' : ''
        }`}
        fluid='lg'
      >
        <Row className='justify-content-between align-items-center position-relative py-3 px-md-4'>
          <Col
            className='d-flex align-items-center justify-content-between py-0'
            lg='2'
          >
            <div className='d-flex align-items-center'>
              <Link to={props.href} className='d-flex align-items-center'>
                <Image
                  src={logo}
                  fluid
                  width='100'
                  height='13'
                  alt='Home page'
                  style={{ display: viewSize == MOBILE_VIEW ? 'none' : '' }}
                />
              </Link>

              <Link to={props.href}>
                <Image
                  src={logoMobile}
                  fluid
                  width='110'
                  height='13'
                  alt='Home page'
                  style={{ display: viewSize == DESKTOP_VIEW ? 'none' : '' }}
                />
              </Link>

              {/* Language switcher on mobile */}
              <div className='d-block d-lg-none ml-1'>
                <LanguageSelect
                  type='flag'
                  size='sm'
                  isMobile={viewSize == MOBILE_VIEW}
                />
              </div>
            </div>
            <a
              href='#'
              onClick={(e) => menuClickHandler(e)}
              className='d-block d-lg-none'
            >
              <Image
                src={hamburgerIcon}
                fluid
                width='27'
                height='13'
                alt='Home page'
              />
            </a>
          </Col>
          <Col
            className={`${
              openMobileMenu ? 'display-mobile' : 'hide-mobile'
            } custom-nav-position mb-5 pt-5 mb-lg-0 py-lg-2`}
            lg='auto'
          >
            <nav aria-label={ariaJSON.navTitle}>
              <ul className={`d-lg-flex align-items-center`}>
                {props.data?.pages.map(function (page, index) {
                  return (
                    <li
                      {...(computedClassName(props.data?.pages, index).length >
                      0
                        ? {
                            className: computedClassName(
                              props.data?.pages,
                              index
                            )
                          }
                        : {})}
                      key={index}
                      data-active={
                        state.location ==
                        `${props.href}${props.data?.pagesLinks?.[index]}`
                      }
                    >
                      {index == 4 || index == 5 ? (
                        <Button
                          className={
                            index == 4
                              ? 'btn-outline-warning px-0 px-lg-4'
                              : 'btn-warning px-0 px-lg-5'
                          }
                          data-index={index}
                          onClick={navigateToCheckout}
                        >
                          {page}
                        </Button>
                      ) : (
                        <Link
                          to={`${props.href}${props.data?.pagesLinks[index]}`}
                        >
                          {page}
                        </Link>
                      )}
                    </li>
                  )
                })}
                <li className='d-none d-lg-block'>
                  <LanguageSelect type='flag' />
                </li>
              </ul>
              <div
                className={`w-100 d-flex justify-content-end d-block d-lg-none`}
              >
                <a
                  href='#'
                  onClick={(e) => menuClickHandler(e)}
                  aria-label={ariaJSON.mobileCloseMenu}
                >
                  <ChevronUp width='35' height='35' />
                </a>
              </div>
            </nav>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Header

// computed func to return specific class for each main navbar list item
const computedClassName = (array, index) => {
  var className = ''
  if (array.length - 1 != index) {
    className += 'mr-lg-3 pr-1 py-1 py-lg-0'
  } else {
    className += 'py-1 py-lg-0'
  }
  return className
}
