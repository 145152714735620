import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Image from '../assets/images/Home-01.jpg'
import { structuredData } from '../structuredDataHelper'

function SEO({
  description,
  lang,
  meta,
  title,
  author,
  img,
  strucData = null
}) {
  const metaDescription = description
  const image_src = img || Image
  const _data =
    strucData != null ? structuredData(strucData, title, img) : strucData
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: 'google-site-verification',
          content: 'googlec9fa5a9947837cb5.html'
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          property: 'og:image',
          content: image_src
        }
      ].concat(meta)}
    >
      <script
        type='text/javascript'
        src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
        async='true'
      ></script>
      <script
        src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_GOOGLE_OPTIMIZE_ID}`}
      ></script>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`}
      ></script>
      <script src='https://checkout-sdk.sezzle.com/checkout.min.js'></script>
      <script
        src={`https://widget.sezzle.com/v1/javascript/price-widget?uuid=${process.env.GATSBY_SEZZLE_UUID}`}
      ></script>
      <script src={process.env.GATSBY_CLEARCO_URL}></script>
      <script
        src={`https://cdn.clear.co/bnpl/bnpl-explainer-1.0.0.js`}
      ></script>
      <script
        src='https://www.google.com/recaptcha/api.js'
        async
        defer
      ></script>
      {_data && <script type='application/ld+json'>{_data}</script>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
