import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../../context/GlobalContextProvider'
import { isEmpty, includes } from 'lodash'
import { DropdownButton, Dropdown, Image } from 'react-bootstrap'
import us_flag from '../../../assets/images/usa_flag.svg'
import ca_flag from '../../../assets/images/canada_flag.svg'
import Cookies from 'js-cookie'

const DISPLAY_TYPE_FLAG = 'flag'

const Language = ({ type, size, isMobile = false }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const [location, setLocation] = useState(false)

  function toggleOpen(lang) {
    Cookies.set('hl-location', lang)
    dispatch({ type: 'SET_LOCALE', payload: { locale: lang } })
  }

  useEffect(() => {
    var locationArray = state.location.split('/')

    const isLocalePresent =
      locationArray.includes('en-ca') || locationArray.includes('en-us')
    if (locationArray.includes('en-ca')) {
      toggleOpen('en-CA')
    }
    if (locationArray.includes('en-us')) {
      toggleOpen('en-US')
    }
    // check if there is a trailing slash and remove it from locationArray
    if (isEmpty(locationArray[locationArray.length - 1])) {
      locationArray.pop()
    }
    // if there are more than 3 sub-path names set to point to home(ex. articles template pages)
    if (locationArray.includes('trademark-search') && state.search) {
      setLocation('trademark-search/' + state.search)
    } else if (locationArray.length > 3 && locationArray.includes('articles')) {
      setLocation('')
    } else if (locationArray.length > 3) {
      // if more than three create a custom path location in case of the pricing page (with locale)
      setLocation(locationArray[2] + '/' + locationArray[3])
    } else if (
      locationArray.length === 3 &&
      locationArray.includes('services')
    ) {
      // if equal to three create a custom path location in case of the pricing page (no locale routes)
      setLocation(locationArray[1] + '/' + locationArray[2])
    } else if (locationArray.length === 3) {
      // for most pages (with locale) just select the page param
      setLocation(locationArray[2])
    } else if (locationArray.length === 2 && !isLocalePresent) {
      // for most pages (no locale) just select the page param
      setLocation(locationArray[1])
    } else {
      setLocation('')
    }
  }, [state.location])

  return type == DISPLAY_TYPE_FLAG ? (
    <div className='flag-switcher'>
      <Dropdown drop='down' alignRight={!isMobile}>
        <Dropdown.Toggle
          className='d-flex py-0 align-items-center'
          id='lang-switcher-flags'
        >
          <Image
            src={state.locale === 'en-US' ? us_flag : ca_flag}
            height='28'
            width='28'
            height={size == 'sm' ? '20' : '28'}
            width={size == 'sm' ? '20' : '28'}
            className='ml-sm-2 mr-1'
            aria-hidden='true'
          ></Image>

          <svg
            width={isMobile ? '9' : '11'}
            height={isMobile ? '6' : '7'}
            viewBox='0 0 11 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.45346 6.3885C5.2055 6.3885 4.9695 6.28992 4.79325 6.11366L0.273345 1.59377C-0.091115 1.22931 -0.091115 0.637805 0.273345 0.273345C0.637805 -0.091115 1.22931 -0.091115 1.59377 0.273345L5.45346 4.13303L9.31315 0.273345C9.67761 -0.091115 10.2691 -0.091115 10.6336 0.273345C10.998 0.637805 10.998 1.22931 10.6336 1.59377L6.11367 6.11366C5.93741 6.28992 5.70141 6.3885 5.45346 6.3885Z'
              fill='#222E35'
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            {...(includes(location, 'book-free-consult')
              ? { as: 'a', href: `/en-us/${location}` }
              : { as: Link, to: `/en-us/${location}` })}
            onClick={() => toggleOpen('en-US')}
          >
            <Image
              src={us_flag}
              height='25'
              width='25'
              className='mx-2'
              aria-hidden='true'
            ></Image>
            United States
          </Dropdown.Item>
          <Dropdown.Item
            {...(includes(location, 'book-free-consult')
              ? { as: 'a', href: `/en-ca/${location}` }
              : { as: Link, to: `/en-ca/${location}` })}
            onClick={() => toggleOpen('en-CA')}
          >
            <Image
              src={ca_flag}
              height='25'
              width='25'
              className='mx-2'
              aria-hidden='true'
            ></Image>
            Canada
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  ) : (
    <DropdownButton
      id='dropdown-basic-button'
      title={state.locale === 'en-US' ? 'United States' : 'Canada'}
    >
      <Dropdown.Item
        {...(includes(location, 'book-free-consult')
          ? { as: 'a', href: `/en-us/${location}` }
          : { as: Link, to: `/en-us/${location}` })}
        onClick={() => toggleOpen('en-US')}
      >
        United States
      </Dropdown.Item>
      <Dropdown.Item
        {...(includes(location, 'book-free-consult')
          ? { as: 'a', href: `/en-ca/${location}` }
          : { as: Link, to: `/en-ca/${location}` })}
        onClick={() => toggleOpen('en-CA')}
      >
        Canada
      </Dropdown.Item>
    </DropdownButton>
  )
}

export default Language
