import React, { useEffect, useContext, useState } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import SEO from '../../seo'
import Header from '../../Global/Header'
import { graphql, Link, StaticQuery } from 'gatsby'
import { Twitter, FacebookSquare, Linkedin } from '@styled-icons/boxicons-logos'
import {
  GlobalDispatchContext,
  GlobalStateContext
} from '../../../context/GlobalContextProvider'
import LanguageSelect from '../../Global/LanguageSelect'
import logoWhite from '../../../assets/images/Wordmark_white.png'
import DiscountBanner from '../../DiscountBanner'

const Layout = ({
  children,
  locale = 'en-US',
  title,
  url = '',
  desc,
  pageImg,
  strucData
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  // onmounted set the location of the user
  useEffect(() => {
    dispatch({
      type: 'SET_LOCATION',
      payload: { location: location.pathname, search: location.search }
    })
  }, [])

  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulHeader {
            edges {
              node {
                pages
                pagesLinks
                mobileMenuBackButton
                subListTitle1
                subListTitle2
                subListTitle3
                subListButton1
                subListButton2
                subListText2
                subListText3
                subListText4
                ariaLabels {
                  internal {
                    content
                  }
                }
                node_locale
              }
            }
          }
          allContentfulFooter {
            edges {
              node {
                linkTitles
                slogan
                resources
                services
                mainLink
                mainLinkHref
                description {
                  description
                }
                company
                resourcesLinks
                servicesLinks
                companyLinks
                contact
                contactLinks
                ariaLabels {
                  internal {
                    content
                  }
                }

                node_locale
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerData = data.allContentfulHeader?.edges.filter(
          (node) => node.node.node_locale === locale
        )
        const footerData = data.allContentfulFooter?.edges.filter(
          (node) => node.node.node_locale === locale
        )
        // getting the json value of the aria obj from contentful
        const ariaJSON = JSON.parse(
          footerData[0]?.node?.ariaLabels.internal.content
        )
        // const cookieJSON = JSON.parse(
        //   footerData[0]?.node?.cookieConsent.internal.content
        // )

        return (
          <>
            <SEO
              title={title || 'Heirlume'}
              description={
                desc ||
                'We had several brand names and logos that needed to be registered both in Canada and the US. Heirlume worked for our tight budget, and we have developed a great partnership!'
              }
              author='Heirlume'
              img={pageImg}
              lang={state.locale}
              strucData={strucData}
            />
            {/* <DiscountBanner locale={state.locale} /> */}
            <Header
              href={state.locale ? `/${state.locale.toLowerCase()}` : '/'}
              data={headerData?.[0]?.node}
              locale={state.locale}
              url={url}
            />
            <main>{children}</main>
            <footer>
              <Container fluid className='bg-footer px-0'>
                <Container>
                  <Row className='align-items-baseline justify-content-between'>
                    <Col md='2'>
                      <a href={locale ? `/${locale.toLowerCase()}` : '/'}>
                        <Image
                          className='mb-n1'
                          src={logoWhite}
                          alt='Heirlume logo'
                          fluid
                          width='130'
                          height='17'
                        />
                      </a>
                    </Col>
                    <Col className='col-12 d-block d-md-none mt-3 mb-5 pb-2'>
                      <p className='desc'>
                        {footerData[0]?.node.description?.description}
                      </p>
                    </Col>
                    <hr className='mobile-hr' />
                    <Col className='mobile-grid' md='auto'>
                      <p className='h5 text-white'>
                        {footerData[0]?.node?.linkTitles[0]}
                      </p>
                      <ul>
                        {footerData[0]?.node.resources?.map(
                          (service, index) => (
                            <li key={service}>
                              <Link
                                to={`/${locale.toLowerCase()}${
                                  footerData[0]?.node.resourcesLinks[index]
                                }`}
                              >
                                {service}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </Col>
                    <hr className='mobile-hr' />
                    <Col className='mobile-grid' md='auto'>
                      <p className='h5 text-white'>
                        {footerData[0]?.node?.linkTitles[1]}
                      </p>
                      <ul>
                        {footerData[0]?.node.services?.map(
                          (resource, index) => (
                            <li key={resource}>
                              <Link
                                to={`/${locale.toLowerCase()}${
                                  footerData[0]?.node.servicesLinks[index]
                                }`}
                              >
                                {resource}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </Col>
                    <hr className='mobile-hr' />
                    <Col className='mobile-grid' md='auto'>
                      <p className='h5 text-white'>
                        {footerData[0]?.node?.linkTitles[2]}
                      </p>
                      <ul>
                        <li key={footerData[0]?.node.company}>
                          <Link
                            to={`/${locale.toLowerCase()}${
                              footerData[0]?.node.companyLinks[0]
                            }`}
                          >
                            {footerData[0]?.node.company[0]}
                          </Link>
                        </li>
                      </ul>
                    </Col>
                    <hr className='mobile-hr' />
                    <Col className='mobile-grid' md='auto'>
                      <p className='h5 text-white'>
                        {footerData[0]?.node?.linkTitles[3]}
                      </p>
                      <ul>
                        {footerData[0]?.node.contact?.map((contact, index) => (
                          <li key={index}>
                            <a href={footerData[0]?.node.contactLinks[index]}>
                              {contact}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </Col>
                    <hr className='mobile-hr' />
                    <Col className='mobile-grid px-lg-5' lg='3'>
                      <p className='h5 text-white'>
                        {footerData[0]?.node?.linkTitles[4]}
                      </p>
                      <LanguageSelect />
                    </Col>
                  </Row>
                  <Row className='bg-trees justify-content-center justify-content-md-start mt-5 pt-2'>
                    <Col
                      className='d-none d-md-block'
                      md={{ span: '8', offset: '2' }}
                    >
                      <p className='desc'>
                        {footerData[0]?.node.description?.description}
                      </p>
                    </Col>
                    <Col
                      className='col-9 d-flex align-items-center flex-column flex-md-row flex-xl-inline mt-md-5 pt-md-3'
                      md={{ span: '7', offset: '3' }}
                    >
                      <p className='slogan'>{footerData[0]?.node.slogan}</p>
                      <hr className='slogan-hr d-none d-md-block' />
                      <a
                        className='slogan-link mt-2 mt-md-0'
                        href={`/${locale.toLowerCase()}${
                          footerData[0]?.node.mainLinkHref
                        }`}
                      >
                        {footerData[0]?.node.mainLink}
                      </a>
                    </Col>
                  </Row>
                </Container>
                <hr className='full-hr d-none d-md-block' />
                <Container className='links-container'>
                  <Row className='justify-content-center justify-content-md-between mt-4 mt-md-5'>
                    <Col className='col-10 text-center text-md-left' md='3'>
                      <p>
                        © 2001-{new Date().getFullYear()} All Rights Reserved.
                      </p>
                    </Col>
                    <Col
                      className='d-flex align-items-center justify-content-center justify-content-md-end mt-2 mt-md-0'
                      md='5'
                    >
                      <Link
                        to={`/${locale.toLowerCase()}/company/terms-conditions`}
                      >
                        Terms & Conditions
                      </Link>
                      <hr className='links-hr' />
                      <Link
                        to={`/${locale.toLowerCase()}/company/privacy-policy`}
                      >
                        Privacy Policy
                      </Link>
                      <a
                        className='social d-none d-md-block ml-4 mr-2'
                        href='https://twitter.com/Heirlumeco'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='invisible-label'>
                          {ariaJSON.twitter}
                        </span>
                        <Twitter
                          style={{
                            width: '18px',
                            height: '18px',
                            color: 'white'
                          }}
                        />
                      </a>
                      <a
                        className='social d-none d-md-block'
                        href='https://www.facebook.com/Heirlumeco/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='invisible-label'>
                          {ariaJSON.facebook}
                        </span>
                        <FacebookSquare
                          style={{
                            width: '18px',
                            height: '18px',
                            color: 'white'
                          }}
                        />
                      </a>
                      {locale === 'en-CA' && (
                        <a
                          className='social d-none d-md-block ml-2'
                          href='https://www.linkedin.com/company/heirlume/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <span className='invisible-label'>
                            {ariaJSON.linkedin}
                          </span>
                          <Linkedin
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white'
                            }}
                          />
                        </a>
                      )}
                    </Col>
                    <Col className='col-12 d-flex d-md-none justify-content-center mt-3'>
                      <a
                        className='social mr-2'
                        href='https://twitter.com/Heirlumeco'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='invisible-label'>
                          {ariaJSON.twitter}
                        </span>
                        <Twitter
                          style={{
                            width: '20px',
                            height: '20px',
                            color: 'white'
                          }}
                        />
                      </a>
                      <a
                        className='social mx-2'
                        href='https://www.facebook.com/Heirlumeco/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <span className='invisible-label'>
                          {ariaJSON.facebook}
                        </span>
                        <FacebookSquare
                          style={{
                            width: '20px',
                            height: '20px',
                            color: 'white'
                          }}
                        />
                      </a>
                      {locale === 'en-CA' && (
                        <a
                          className='social ml-2'
                          href='https://www.linkedin.com/company/heirlume/'
                          target='_blank'
                          rel='noreferrer'
                        >
                          <span className='invisible-label'>
                            {ariaJSON.linkedin}
                          </span>
                          <Linkedin
                            style={{
                              width: '20px',
                              height: '20px',
                              color: 'white'
                            }}
                          />
                        </a>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Container>
              {/* <CookieConsent cookieData={cookieJSON} /> */}
            </footer>
          </>
        )
      }}
    />
  )
}

export default Layout
