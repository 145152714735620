import favicon from './assets/images/favicon.png'
import { SCHEMATYPES } from './constants'
import { values } from 'lodash'

/**
 * Structured data creation function.
 * @param {Object} strucData data realted payload
 * @param {String} title article title
 * @param {String} img article image url
 * @returns {JSON} Schema object in json to be appended to script
 */
export function structuredData(strucData, title, img) {
  let struct_data = {}

  switch (strucData.type) {
    case SCHEMATYPES.ARTICLE:
      struct_data = getArticleData(strucData.articleData, title, img)
      break
    case SCHEMATYPES.FAQ:
      struct_data = getFAQData(strucData.FAQs)
      break
    default:
      break
  }

  return JSON.stringify(struct_data)
}

/**
 * Article Schema function.
 * @param {Object} articleData article payload
 * @param {String} title article title
 * @param {String} img article img url
 * @returns {JSON} Schema object
 */
function getArticleData(articleData, title, img) {
  return {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${location.origin}${articleData.url}`
    },
    headline: title,
    image: [img],
    datePublished: articleData.createdAt,
    dateModified: articleData.updatedAt,
    publisher: {
      '@type': 'Organization',
      name: 'Heirlumeco',
      logo: {
        '@type': 'ImageObject',
        url: favicon
      }
    }
  }
}

/**
 * FAQ Schema function.
 * @param {Object} FAQs all the faq questions
 * @returns {JSON} Schema object
 */
function getFAQData(FAQs) {
  let faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: []
  }
  // getting the faqs from the object
  var questionCollection = values(FAQs).map((items) => {
    return items.node.faq
  })
  // getting the main prop that holds the QA of the faq
  var questions = values(questionCollection).map((questions) => {
    return values(questions).map((question) => {
      return question.node
    })
  })
  // loop each FAQ QA and add to mainEntity array
  questions.map((collections) => {
    return collections.map((item) => {
      if (item.question && item.answer) {
        faqSchema.mainEntity.push({
          '@type': 'Question',
          name: item.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: item.answer
          }
        })
      }
    })
  })

  return faqSchema
}
